 /*NAVIGATION*/
 .top-bar {
   background-color: #fff;
   transition: all 0.5s ease-in;

   a {
     font-family: 'Nunito';
   }

   a:hover {
     color: #C97064;
   }

   &.navigation2 {
     background-color: transparent;
     transition: all 0.5s ease-in;

     a {
       transition: all 0.5s ease-in;
       color: #fff;
     }

     a:hover {
       color: #C97064;
     }


     .button-1 {
       color: #fff;
       border-color: #fff;
       transition: all 0.5s ease-in;
     }

     .button-1::before {
       background: #fff;
     }

     .button-1:hover,
     .button-1:focus {
       color: #C97064;
     }
   }
 }

 .navigation-transparent {
   position: absolute;
   width: 100%;
   left: 0;
 }

 .top-bar ul {
   background-color: transparent;
 }

 .top-bar {
   padding: 1em 8em;

   a {
     transition: all 0.5s ease-in;
     color: #1E2028;
     font-size: 1.1rem;
   }

   .button-1 {
     transition: all 0.5s ease-in;
   }
 }

 .sticky {
   width: 100%;
   z-index: 4;
 }

 .top-bar-right {
   text-align: right;
 }

 .top-bar {
   img {
     height: 100px;
   }
 }

 .title-bar {
   background-color: white;
 }
 .is-active,
 .button-1::before {
   color: #C97064!important;
 }
 .menu-icon::after {
   background: #C97064;
   transition: all 0.5s ease-in;
   box-shadow: 0 7px 0 #C97064, 0 14px 0 #C97064;
   -webkit-box-shadow: 0 7px 0 #C97064, 0 14px 0 #C97064;
 }

 .menu-icon:hover:after {
   /* the block itself, the first line */
   background: #1E2028;
   /* middle and bottom */
   box-shadow: 0 7px 0 #1E2028, 0 14px 0 #1E2028;
   /* respect WebKit */
   -webkit-box-shadow: 0 7px 0 #1E2028, 0 14px 0 #1E2028;
 }

 .title-bar-left {

   img {
     height: 100px;
   }
 }

 /*SONSTIGES UND SCHRIFTEN*/
 h1,
 h2,
 h3 {
   font-family: 'Open Sans';
   color: #1E2028;
   font-weight: 700;
   text-rendering: optimizeLegibility;
 }

 p {
   font-family: 'Nunito';
   color: #1E2028;
   margin-bottom: 0rem;
   text-rendering: optimizeLegibility;
 }

 /*SONSTIGES - BUTTONS*/
 .button-1 {
   letter-spacing: 3px;
   cursor: pointer;
   text-decoration: none;
   transition: width .3s, color .3s;
   color: #1E2028;
   vertical-align: middle;
   transform: perspective(1px) translateZ(0);
   position: relative;
   transition-property: color;
   transition-duration: 0.3s;
   padding: 15px 40px 15px 40px;
   border: 1px solid #1E2028;
   text-transform: uppercase;
   font-size: 1.1rem;
   font-family: 'Open Sans';
   display: inline-block;
 }

 .button-1::before {
   content: "";
   position: absolute;
   z-index: -1;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #1E2028;
   transform: scaleX(0);
   transform-origin: 0 50%;
   transition-property: transform;
   transition-duration: 0.3s;
   transition-timing-function: ease-out;
 }

 .button-1:hover,
 .button-1:focus {
   transition-duration: 0.3s;
   color: #fff;
 }

 .button-1:hover::before,
 .button-1:focus::before {
   transform: scaleX(1);
 }

 .button-2 {
   padding: 15px 40px 15px 40px;
   display: inline-block;
   color: white;
   background: rgba(0, 0, 0, 0.5);
   text-transform: uppercase;
   font-size: 1.1rem;
   font-family: 'Open Sans';
   letter-spacing: 3px;
   cursor: pointer;
   text-decoration: none;
   transition: width .3s, color .3s;
   vertical-align: middle;
   transform: perspective(1px) translateZ(0);
   position: relative;
 }

 .button-2::before {
   content: "";
   position: absolute;
   z-index: -1;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #1E2028;
   transform: scaleX(0);
   transform-origin: 0 50%;
   transition-property: transform;
   transition-duration: 0.3s;
   transition-timing-function: ease-out;
 }

 .button-2:hover,
 .button-1:focus {
   color: #fff;
 }

 .button-2:hover::before,
 .button-1:focus::before {
   transform: scaleX(1);
 }

 .button-3 {
   color: #1E2028;
   border-bottom: 2px solid #1E2028;
   font-size: 1.4rem;
   text-transform: uppercase;
   width: auto;
   display: inline-block;
   padding-bottom: 0.2rem;
   font-family: 'Open Sans';
   transition: all 0.2s ease-in;
 }

 .button-3:hover {
   color: #C97064;
   border-bottom: 2px solid #C97064;
 }

 .button-4 {
   color: #1E2028;
   display: block;
   padding-bottom: 0.2rem;
   font-family: 'Open Sans';
   transition: all 0.2s ease-in;

   &:hover {
     color: #C97064;
   }
 }

 .big-button-text {
   display: inline;
   color: #C97064;
   font-weight: 800;
 }

 //SONSTIGES - FORM
 input {
   background-color: transparent;
 }

 form {
   background-color: #F2F2F2;
   padding: 2em;
 }

 //SONSTIGES - ARROW and ANIMATION
 $ani-speed: 2s;
 $ani-delay: 1s;

 .arrow {
   opacity: 0;
   position: absolute;
   left: 50%;
   bottom: 10%;
   transform-origin: 50% 50%;
   transform: translate3d(50%, 50%, 0);
 }

 .arrow-first {
   animation: arrow-movement $ani-speed ease-in-out infinite;
 }

 .arrow-second {
   animation: arrow-movement $ani-speed $ani-delay ease-in-out infinite;
 }

 .arrow:before,
 .arrow:after {
   background: #fff;
   content: '';
   display: block;
   height: 3px;
   position: absolute;
   top: 0;
   left: 0;
   width: 30px;
 }

 .arrow:before {
   transform: rotate(45deg) translateX(-23%);
   transform-origin: top left;
 }

 .arrow:after {
   transform: rotate(-45deg) translateX(23%);
   transform-origin: top right;
 }

 // Animation
 @keyframes arrow-movement {
   0% {
     opacity: 0;
     bottom: 15%;

   }

   70% {
     opacity: 1;
   }

   100% {

     opacity: 0;
   }
 }

 //HOME and HERO'S
 #home-hero-1 {
   position: relative;
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/zowal/zowal-bg-home.jpg);
   z-index: 1;

   &:before {
     background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../img/zowal/zowal-bg-home.jpg);
   }
 }

 #home-hero-2 {
   position: relative;
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/mafra/mafra-bg-home.jpg);
   z-index: 1;

   &:before {
     background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../img/mafra/mafra-bg-home.jpg);
   }
 }

 #home-hero-3 {
   position: relative;
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/alsecco/alsecco_hala.jpg);
   z-index: 1;

   &:before {
     background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../img/alsecco/alsecco_hala.jpg);
   }
 }

 #hero-about {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/zowal/zowal-eingang.jpg);
 }

 #hero-kontakt {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/zowal/zowal-bg-home.jpg);
 }

 #hero-produkte {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/zowal/zowal-produkte.jpg);
 }

 #hero-mafra {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/mafra/mafra-bg.jpg);
 }

 #hero-alsecco {
   background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/alsecco/alsecco_hala.jpg);
 }

 .hero {
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
 }

 .hero-full {
   height: 100vh;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   
 }

 .hero-half {
   height: 50vh;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
 }

 .hero-home {
   height: 86vh;

   &:before {
     content: '';
     display: flex;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     position: absolute;
     top: 0;
     left: 0;
     opacity: 0;
     height: 86vh;
     width: 100%;
     z-index: -1;
     transition: opacity 1s;
   }

   &:hover:before {
     opacity: 1;
   }
 }


 .hero-inner-inhaltsseite {
   text-align: center;
   max-width: 900px;
   margin: auto;

   h2,
   p {
     color: white;
   }

   .button-2 {
     margin-top: 3em;
   }
 }

 .hero-inner-produktseite {
   text-align: center;
   max-width: 900px;
   margin: auto;

   h1 {
     font-weight: 800;
     text-transform: uppercase;
     font-size: 7rem;
     color: #C97064;
   }

   h2 {
     text-transform: uppercase;
     color: #fff;
     font-size: 1.2rem;
   }

   p {
     color: #fff;
   }
 }

 .hero-inner-home {
   justify-content: center;
   flex-direction: column;
   display: flex;
   max-width: 90%;
   padding: 40px;

   h2,
   h3 {
     color: white;
   }


 }

 .hero-inner-home h3,
 .about-home h2 {
   font-family: 'Nunito';
   font-size: 1.3rem;
   text-transform: uppercase;
 }

 .hero-inner-home-half {
   display: inline;
   position: absolute;
   bottom: 2em;
   left: 2em;

   hr {
     margin: -13px auto;
   }

   h2,
   p {
     color: #fff;
   }

   h2 {
     text-transform: uppercase;
   }
 }



 hr {
   max-width: inherit;
 }



 //HOME - About
 .about-home {
   background-color: #F2F2F2;
   padding-top: 15em;
   padding-bottom: 50em;
   position: relative;
   z-index: 1;


   h1 {
     max-width: 15em;
     font-weight: 800;
   }

   p {
     margin-top: 2em;
     margin-bottom: 2em;
     max-width: 35em;
     margin-left: auto;
     margin-right: auto;
   }
 }

 .img-right {
   position: absolute;
   right: 0;
   top: 100px;
   height: 45em;
   z-index: -1;
 }

 //Main
 .main {
   padding-bottom: 5em;

   &#kontakt {
     padding-bottom: 0em;
   }

   &#datenschutzerklaerung,
   &#impressum {
     padding-top: 5em;
   }
 }

 //ABOUT
 .about-imagetext {
   padding: 3em 0 3em 0;

   h1 {
     font-weight: 800;
   }
 }

 .about-text {
   padding-left: 2em;
 }

 .ueberuns {
   margin: 0em 0;
 }

 //Produkthighlight und Produktauswahl
 .produkt-highlight {
   margin-top: -25em;
   z-index: 2;
   position: relative;

   p,
   h2 {
     color: #fff;
   }

   h2 {
     font-weight: 800;
     text-transform: uppercase;
     font-size: 5rem;
   }

   h4 {
     font-weight: 800;
     text-transform: uppercase;
     font-size: 1rem;
     color: #C97064;
   }

   .button-2 {
     margin-top: 3em;
   }

   .button-3 {
     margin: 2em 0;
   }
 }

 #produkte .produkt-highlight {
   margin-top: inherit;
 }

 .produkt-bg {
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   padding: 13em 0;
 }

 .produkt-bg-inner {
   text-align: center;
 }

 //Produktseiten
 .box {
   background-color: #fff;
   margin-top: -10em;
   box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
   padding: 20px;

   .button-3 {
     font-size: 1rem;
   }
 }

 .highlights {
   padding: 3em 0;

   h2 {
     padding-bottom: 1em;
     text-transform: uppercase;
     color: #C97064;
   }

   h3 {
     padding: 1em 0;
   }

   p {
     padding-bottom: 2.5em;
   }

   h3,
   p {
     max-width: 25em;
   }
 }

 .imagesA1 {
   position: absolute !important;
   height: 300px;
   width: 420px;
   z-index: 2;
   margin-top: -5em;

 }

 .imagesA2 {
   position: absolute !important;
   z-index: 1;
   height: 210px;
   width: 320px;
   margin-left: 15em;
 }

 .imagesAText {
   position: absolute !important;
   margin-left: 27em;
   margin-top: -3em;
   text-align: left;
 }

 .produkt-image-stack {
   h2 {
     padding-bottom: 1em;
     text-transform: uppercase;
     color: #C97064;
   }

   h3 {
     color: #C97064;
   }

   h4 {
     font-family: 'Nunito';
   }

   p {
     padding: 1em 0 2em;
   }
 }

 .produkt-image-stack-margin {
   margin: 15em 0;
 }

 #produkt-image-stack-margin-0 {
   margin: 4em 0 15em;
 }

 .produkt-text {
   padding: 0 0 3em;

   h2 {
     padding-bottom: 1em;
     text-transform: uppercase;
     color: #C97064;
   }
 }

 .produkt-dokumente {
   background-color: #f2f2f2;
   padding: 3em 0;
 }

 .datenblaetter {
   border-bottom: solid 1px #bebebe;
   padding: 2em 0;
 }

 .datenblaetter:hover {
   background-color: #ffffff;

 }

 .icon {
   fill: #C97064 !important;
 }

 .produkte-grid {
   padding: 2em 0;

   .produkte-grid-text {
     padding: 2em 0 0;
   }

   .produkt-bg {
     padding: 5em 5em;

     p,
     h2 {
       color: #fff;
     }

     h2 {
       font-weight: 800;
       text-transform: uppercase;
       font-size: 3rem;
     }

     h4 {
       font-weight: 800;
       text-transform: uppercase;
       font-size: 1rem;
       color: #C97064;
     }

     .button-2 {
       margin-top: 3em;
     }
   }
 }

 .big-number {
   p {
     color: #C97064;
     font-weight: 800;
     font-family: 'Open Sans';
     font-size: 3rem;
   }

 }

 img.icon {
   height: 80px;
 }

 //Container
 #container {
   max-width: 1200px;
 }

 #container-2 {
   max-width: 1500px;
 }


 //Footer
 .footer {
   background-color: #F2F2F2;
   padding: 2em 0 1em 0;

   p {
     font-size: 1.3rem;
   }

   a {
     font-size: 1.6rem;
   }

   hr {
     padding: 1em 0;
   }
 }



 /* Small only */
 @include breakpoint(small only) {

   /*HOME*/
   .hero-home,
   .hero-home:before {
     height: 29vh;
   }

   .about-home {
     padding: 5em 0 30em 0;
   }

   .produkt-bg {
     padding: 5em 0;
   }

   footer {
     text-align: center;
   }

   .img-right {
     display: none;
   }

   .produkt-highlight h2 {
     font-size: 3.5rem;
   }

   .arrow {
     left: 45%;
   }

   .produkt-bg {
     margin: 0.3em 0;
   }

   .text-center-small {
     text-align: center;
   }

   .produkte-grid .produkt-bg h2 {
     font-size: 2.5rem;
   }

   .hero-inner-produktseite h1 {
     font-size: 4rem;
   }

   .box {
     margin: -10em 1em 0;
   }

   .menu.medium-horizontal {
     flex-direction: column;
   }

   .top-bar .top-bar-right {
     margin-left: 0;
     text-align: left;
   }

   .top-bar {
     padding: 1em 0;
   }

   .align-middle {
     -webkit-box-align: inherit;
     -webkit-align-items: inherit;
     -ms-flex-align: inherit;
     align-items: inherit;
   }

   .top-bar.navigation2 {
     background-color: #ffffff;
     -webkit-transition: all 0.5s ease-in;
     transition: all 0.5s ease-in;

     a {
       color: #1E2028;
     }

     .button-1::before {
       background: black;
     }

     .button-1 {
       color: #1E2028;
       border-color: #1E2028;
       transition: all 0.5s ease-in;
     }

     .button-1:hover,
     .button-1:focus {
       color: white;
     }

   }

   .navigation-transparent {
     position: inherit;
     width: inherit;
     left: inherit;
   }

   .main{
     h2 {
       padding-bottom: 0;
       font-size: 2.25rem;
     }
   }
   .imagesA2 {
    z-index: -1;
    margin-top: -7em;
    margin-left: 0;
  }
  .imagesAText {
    margin-left: 20em;
    margin-top: -1em;
}
.imagesA1 {
  position: inherit !important;
  height: inherit;
  width: inherit;
  margin-top: 3em;

}
 }


 /* Medium and up */
 @media screen and (min-width: 40em) {}

 /* Medium only */
 @include breakpoint(medium only) {

   /*Hero*/
   .hero-home,
   .hero-home:before {
     height: 45vh;
   }

   .about-home {
     padding: 5em 0 30em 0;
   }

   footer {
     text-align: center;
   }

   .img-right {
     display: none;
     position: relative;
     right: inherit;
     top: inherit;
     height: inherit;
   }

   .produkt-highlight h2 {
     font-size: 2rem;
   }

   .box {
     margin: -10em 1em 0;
   }

   .top-bar .top-bar-left {
     display: none;
   }

   .menu.medium-horizontal {
     flex-direction: column;
   }

   .top-bar .top-bar-right {
     margin-left: 0;
     text-align: left;
   }

   .top-bar {
     padding: 1em 0;
   }

   .align-middle {
     -webkit-box-align: inherit;
     -webkit-align-items: inherit;
     -ms-flex-align: inherit;
     align-items: inherit;
   }

   .top-bar.navigation2 {
     background-color: #ffffff;
     -webkit-transition: all 0.5s ease-in;
     transition: all 0.5s ease-in;

     a {
       color: #1E2028;
     }

     .button-1::before {
       background: black;
     }

     .button-1 {
       color: #1E2028;
       border-color: #1E2028;
       transition: all 0.5s ease-in;
     }

     .button-1:hover,
     .button-1:focus {
       color: white;
     }

   }

   .navigation-transparent {
     position: inherit;
     width: inherit;
     left: inherit;
   }
   #mafra .main,
   #alsecco .main {
     h2 {
       padding-bottom: 0;
       font-size: 2.25rem;
     }
   }
   .imagesA2 {
    z-index: -1;
    margin-top: -7em;
    margin-left: 0;
  }
  .imagesAText {
    margin-left: 20em;
    margin-top: -1em;
}
.imagesA1 {
  position: inherit !important;
  height: inherit;
  width: inherit;
  margin-top: 3em;

}
 }

 /* Large and up */
 @media screen and (min-width: 64em) {}

 /* Large only */
 @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
 
 .no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}